import { Component } from 'react'
import './MyCal.css';
import MyCalKey from './MyCalKey'

const OpFunctions = [
  { '+': (a,b) => a+b },
  { '-': (a,b) => a-b },
  { '*': (a,b) => a*b },
  { '/': (a,b) => a/b },
  { '%': (a,b) => a%b },
  { '^': (a,b) => a**b },
  { 'sqrt': (a) => Math.sqrt(a) }  
];

export default class MyCal extends Component {

  constructor(props) {
    super(props)

  }
    
  handleDigitsClick = () => {

  }

  handleAnswerClick = () => {

  }

  handleOperandClick = () => {

  }


  render() {
    console.log(this.state);
    return <div>
        <div>
          <MyCalKey id="sqrt" value="√" onChange="this.handleDigitsClick"/>
          <MyCalKey id="power" value="^" onChange="this.handleOperandClick"/>
          <MyCalKey id="mod" value="%" onChange="this.handleOperandClick"/>
          <MyCalKey id="div" value="÷" onChange="this.handleOperandClick"/>
        </div>
        <div>
          <MyCalKey id="7" value="7" onChange="this.handleDigitsClick"/>
          <MyCalKey id="8" value="8" onChange="this.handleDigitsClick"/>
          <MyCalKey id="9" value="9" onChange="this.handleDigitsClick"/>
          <MyCalKey id="multi" value="X" onChange="this.handleOperandClick"/>
        </div>
        <div>
          <MyCalKey id="4" value="4" onChange="this.handleDigitsClick"/>
          <MyCalKey id="5" value="5" onChange="this.handleDigitsClick"/>
          <MyCalKey id="6" value="6" onChange="this.handleDigitsClick"/>
          <MyCalKey id="minus" value="-"/>
        </div>
        <div>
          <MyCalKey id="1" value="1" onChange="this.handleDigitsClick"/>
          <MyCalKey id="2" value="2" onChange="this.handleDigitsClick"/>
          <MyCalKey id="3" value="3" onChange="this.handleDigitsClick"/>
          <MyCalKey id="plus" value="+"/>
        </div>
        <div>
          <MyCalKey id="0" value="0" onChange="this.handleDigitsClick"/>
          <MyCalKey id="." value="." onChange="this.handleDigitsClick"/>
          <MyCalKey id="=" value="=" onChange="this.handleDigitsClick"/>
          <MyCalKey id="cancel" value="c"/>
        </div>
      </div>
    }
}

