import { Component } from 'react'

export default class MyCalKey extends Component {
  render() {
    const { ...props } = this.props
    
    return (
      <input type="button" value={props.value} />
    );
    /*
    return (
      <button {...props}/>
    );*/
  }
}