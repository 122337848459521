//import logo from './logo.svg';
import './App.css';
import MyCal from './MyCal';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <p>
          My Calculator.
        </p>
      </header>
      <body>
        <MyCal/>
      </body>
    </div>
  );
}

export default App;
